<template>
    <div class="test-timer">
        <div class="test-end__title">
            <div class="test-end__pic">
                <img class="test-end__img" src="~assets/img/favicons/bad-icon.svg" alt="" width="55" height="55">
            </div>
            <div class="test-repeat__title">
                Тестирование не пройдено.
            </div>
            <div class="test-repeat__subtitle">
                Количество попыток переаттестации исчерпаны.
            </div>
        </div>

        <button
            class="v-btn"
            @click="$router.back()"
        >
            Вернуться назад
        </button>
    </div>
</template>

<script>
    export default {
        name: 'Attempts'
    };
</script>

<style scoped></style>
